import React from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      George Coyne
      <br />
      George Coyne is the head of engineering at Slate Data
    </>
  )
}
